const videos = [...document.getElementsByClassName('video-element')];

export function startVideo() {
    if (videos.length) {
        videos.map(video => {
            video.addEventListener('click', function () {
                video.setAttribute('controls', 'true');
            })
        })
    }
}